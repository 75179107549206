import React from "react";
import { Navbar, Nav, Button, Container, Dropdown } from "react-bootstrap";
import "../CSS/navbar2.css";
import Whitelogo from "../img/biotechwhite.png";
import Blacklogo from "../img/biotechblack.png";
import { NavLink } from "react-router-dom";

const AppNavbars = ({ pageIndex }) => {
  const logoSrc = pageIndex === 2 || pageIndex === 4 ? Blacklogo : Whitelogo;
  return (
    <div
      className={
        pageIndex === 2 || pageIndex === 4
          ? "navbar2_nav navbar2_nav2"
          : "navbar2_nav"
      }
    >
      <Navbar expand="lg" style={{ zIndex: 9 }}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logoSrc} alt="" height={100} width={150} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
            style={{ marginRight: 200 }}
          >
            <Nav className="mr-auto" style={{ gap: 50 }}>
              <NavLink to={"/about"}>About Us</NavLink>
              <NavLink to={"/products"}>Products</NavLink>
              <Dropdown>
                <Dropdown.Toggle
                  className="hstack"
                  variant="none"
                  id="dropdown-basic"
                >
                  <NavLink to={"/service"}>Services</NavLink>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="clinical-data-management">
                    Clinical Data Management
                  </Dropdown.Item>
                  <Dropdown.Item href="biostatic-programming">
                    Biostatic Programming
                  </Dropdown.Item>
                  <Dropdown.Item href="data-standardization">
                    Data standardization
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <NavLink to={"/blog"}>Blog</NavLink>
              <NavLink to={"/contact-us"}>Contact Us</NavLink>
            </Nav>
            <div className="buttons_types" style={{ marginLeft: 50 }}>
              <NavLink to={"/contact-us"}>
                <Button>Request Demo</Button>
              </NavLink>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default AppNavbars;
