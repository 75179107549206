import React from "react";
import "../CSS/mainservice.css";
import { Col, Container, Row } from "react-bootstrap";
import Partner from "../img/about51.jpg";
import Ourjourney from "../img/service23.jpg";
import Complience from "../img/clinicaldatamanagement.jpg";
import AppNavbar from "../Component/Navbars";
import { NavLink } from "react-router-dom";
import Footer from "../Component/footer";
import { Helmet } from "react-helmet";

const mainService = () => {
  return (
    <>
      <Helmet>
        <title>Services</title>
        <meta
          name="description"
          content="From comprehensive Clinical Data Services to advanced Biostatistics and Statistical Programming, coupled with meticulous Data Standardization, we ensure precision, reliability, and innovation in healthcare analytics"
        />
        <meta name="keywords" content="services, galaxbiotech" />
      </Helmet>
      <AppNavbar />
      <div className="main_serve">
        <Container className="contains">
          <Row>
            <Col xl={4} lg={4} md={6} sm={12} className="col-12">
              <div className="mainServices1">
                <img className="comp_img" src={Complience} alt="" />
                <div className="partners_maintime">
                  <b>Clinical Data Management </b>
                  <p style={{fontWeight: 300}}>
                    At Galax Biotech, we understand the critical nature of data
                    management in the success of any clinical trial. Our team of
                    experienced professionals is dedicated to providing you with
                    top-notch services that will streamline...
                  </p>
                  <div className="read_moreB">
                    <NavLink to={"/clinical-data-management"}>
                      <button>Read More</button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={6} sm={12} className="col-12">
              <div className="mainServices1">
                <img className="comp_img" src={Ourjourney} alt="" />
                <div className="partners_maintime">
                  <b>Biostatistics & Programming</b>
                  <p style={{fontWeight: 300}}>
                    We are committed to providing reliable statistical support
                    that meets the highest standards of quality and regulatory
                    compliance. With our expertise, you can confidently navigate
                    the complex statistical...
                  </p>
                  <div className="read_moreB">
                    <NavLink to={"/biostatic-programming"}>
                      <button>Read More</button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={6} sm={12} className="col-12">
              <div className="mainServices1">
                <img src={Partner} alt="" />
                <div className="partners_maintime">
                  <b>Data standardization</b>
                  <p style={{fontWeight: 300}}>
                    Galax Biotech, as a leading provider of data standardization
                    services tailored specifically for clinical trials and
                    research projects. At Galax Biotech, we providing you with
                    top-notch data standardization services including...
                  </p>
                  <div className="read_moreB">
                    <NavLink to={"/data-standardization"}>
                      <button>Read More</button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default mainService;
