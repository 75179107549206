import React from "react";
import "../CSS/services.css";
import { Col, Container, Row } from "react-bootstrap";
import FormPage from "./form";
import AppNavbar from "../Component/Navbars";
import Service2 from "../img/service23.jpg";
import Documents from "../img/document.png";
import Manual from "../img/manualbook.png";
import Auditing from "../img/auditing.png";
import Tracking from "../img/tracking.png";
import Footer from "../Component/footer";
import { Helmet } from "react-helmet";

const Services2Page = () => {
  return (
    <>
      <Helmet>
        <title>Biostatistics & Programming</title>
        <meta
          name="description"
          content="We are committed to providing reliable statistical support that meets the highest standards of quality and regulatory compliance. With our expertise, you can confidently navigate the complex statistical landscape and derive meaningful insights from your data."
        />
        <meta
          name="keywords"
          content="Biostatistics, Programming, services, galaxbiotech"
        />
      </Helmet>
      <AppNavbar />
      <div className="capable_container">
        <div className="capability_pages">
          <Container>
            <Row className="contains gx-5">
              <Col md={6}>
                <div className="services_rainvent">
                  <p>
                    <b>Biostatistics & Programming</b>
                  </p>
                  <p style={{fontWeight: 300}}>
                    We are committed to providing reliable statistical support
                    that meets the highest standards of quality and regulatory
                    compliance. With our expertise, you can confidently navigate
                    the complex statistical landscape and derive meaningful
                    insights from your data."
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="products1_rainvent">
                  <img src={Service2} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="capabless_container">
        <div className="capability_pages">
          <Container>
            <div className="widgets23_of_services">
              <Row>
                <Col md={3}>
                  <div className="service23__wizards1">
                    <img src={Documents} alt="" />
                    <h6>Classic to Modern Randomization</h6>
                    <p style={{fontWeight: 300}}>
                      We have extensive experience in randomization techniques,
                      ranging from classic block randomization to advanced
                      methods like stratified randomization and minimization
                      algorithms.
                    </p>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="service23__wizards1">
                    <img src={Manual} alt="" />
                    <h6>
                      SAP (Statistical Analysis Plan) and Mock Shells Writing
                    </h6>
                    <p style={{fontWeight: 300}}>
                      Our experts can assist you in developing a comprehensive
                      SAP tailored to your study objectives while ensuring
                      regulatory compliance. We also provide mock shells as a
                      blueprint for efficient data analysis.
                    </p>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="service23__wizards1">
                    <img src={Auditing} alt="" />
                    <h6>Statistical Programming and Validation</h6>
                    <p style={{fontWeight: 300}}>
                      We offer reliable statistical table, Listings and Figures
                      programming services using industry-standard software
                      tools such as SAS and R. Our rigorous validation processes
                      guarantee accurate results for your analysis needs.
                    </p>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="service23__wizards1">
                    <img src={Tracking} alt="" />
                    <h6>
                      PK/PD, BA/BE, QT/QTc, Meta Data, Interim, ISS, ISE, and Ad
                      hoc/Post hoc Analysis
                    </h6>
                    <p style={{fontWeight: 300}}>
                      Our team has extensive experience in conducting a wide
                      range of analyses, including pharmacokinetic /
                      pharmacodynamic (PK/PD), bioavailability/ bioequivalence
                      (BA/BE), QT/QTc studies, meta-analyses, interim analyses,
                      and ad hoc/post hoc analyses.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <div className="capableity_container">
        <div className="capability_pages">
          <Container>
            <div className="widgets23_of_services">
              <Row>
                <Col md={3}>
                  <div className="service23__wizards1">
                    <img src={Documents} alt="" />
                    <h6>Statistical Inputs into Protocol Inputs</h6>
                    <p style={{fontWeight: 300}}>
                      Our experienced team of statisticians can provide valuable
                      insights and recommendations during the protocol
                      development phase. We understand the importance of
                      incorporating statistical considerations from the outset
                      to ensure robust study design
                    </p>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="service23__wizards1">
                    <img src={Manual} alt="" />
                    <h6>Protocol Design</h6>
                    <p style={{fontWeight: 300}}>
                      We specialize in designing protocols that maximize the
                      efficiency and accuracy of data collection, analysis, and
                      interpretation. Our expertise covers various study
                      designs, including randomized controlled trials,
                      observational studies, and adaptive designs.
                    </p>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="service23__wizards1">
                    <img src={Auditing} alt="" />
                    <h6>Statistical Methodology</h6>
                    <p style={{fontWeight: 300}}>
                      We employ state-of-the-art statistical methodologies
                      tailored to your specific research objectives. Our team is
                      skilled in hypothesis setup, sample size determination,
                      power calculations, and other advanced techniques
                      necessary for sound statistical inference.
                    </p>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="service23__wizards1">
                    <img src={Tracking} alt="" />
                    <h6>Advisory Consulting</h6>
                    <p style={{fontWeight: 300}}>
                      Our statisticians are available to offer advisory
                      consulting throughout your project's lifecycle. Whether
                      you need guidance on data collection methods or assistance
                      with interpreting complex statistical outputs, we're here
                      to help.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <div className="capables1_containers1">
        <div className="capability_pages">
          <Container className="contains gx-5">
            <Row>
              <Col md={6}>
                <div className="services_rainvent">
                  <p>
                    <b>Request for services</b>
                  </p>
                  <p style={{fontWeight: 300}}>
                    We would be delighted to discuss your specific requirements
                    further and provide a tailored solution that aligns with
                    your project goals. Please feel free to reach out to us at
                    any time via email or phone.
                  </p>
                  <p style={{fontWeight: 300}}>
                    Thank you for considering our services. Looking forward to
                    the opportunity of collaborating with you.
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="services_rainvents1">
                  <FormPage />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Services2Page;
