import React from "react";
import "../CSS/abouthomepage.css";
import { NavLink } from "react-router-dom";
import aboutImage from "../img/AboutHome.png";
import { Col, Container, Row } from "react-bootstrap";

function Abouthomepage() {
  return (
    <Container  fluid
    style={{
      padding: 0,
      margin: 0,
      width: "100vw",
      height: "100vh",
      overflow: "hidden",
    }}>
      <Row style={{ margin: 0, height: "100%", width: "100%" }}>
        <Col id="about" className="d-flex justify-content-center align-items-center"
          style={{ padding: 0, height: "100%" }}>
          <div className="about_homes" style={{ height: "100%", width: "72%" }}>
          <NavLink to={"/about"}>
            <img src={aboutImage}  alt="About Us" style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}/>
            </NavLink>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Abouthomepage;
