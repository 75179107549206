import React from "react";
import "../CSS/about.css";
import { Helmet } from "react-helmet";
import ClinicT from "../img/about51.jpg";
import Footer from "../Component/footer";
import AiPower from "../img/aipower1.jpg";
import aboutimg from "../img/eightth1.jpg";
import AboutImg3 from "../img/about21.jpg";
import dotImage from "../img/dotImage.png";
import AppNavbar from "../Component/Navbars";
import { Col, Container, Row } from "react-bootstrap";
const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>About</title>
        <meta
          name="description"
          content="Pioneering the Future of Clinical Trials with AI-Driven CTMS Solutions. Welcome to GALAX BIOTECH CTMS Solutions, where innovation meets clinical excellence."
        />
        <meta name="keywords" content="About, galaxbiotech" />
      </Helmet>
      <AppNavbar />
      <div className="about_container">
        <div className="about_page">
          <Container>
            <Row className="contains gx-5">
              <Col md={4} className="position-relative">
                <div className="circleDiv" style={{
                  width: '100px',
                  height: '100px',
                  backgroundColor: '#b39326',
                  borderRadius: '50%',
                  position: "absolute",
                  right: -14,
                  top: -35,
                  zIndex: 0,
                }}>
                </div>
                <div
                  className="circleDiv"
                  style={{
                    width: '222px',
                    height: '85px',
                    borderRadius: "4pc",
                    backgroundColor: '#165f0f',
                    position: "absolute",
                    left: -50,
                    bottom: -36,
                    zIndex: 0,
                  }}>
                </div>
                <div className="about_img">
                  <img src={aboutimg} alt="" style={{ position: "relative" }} />
                </div>
              </Col>
              <Col md={8}>
                <div className="aboutText">
                  <p className="pionner" style={{ fontSize: 24, fontWeight: 500 }}>
                    Galaxbiotech — Where Technology Meets Clinical Excellence
                  </p>
                  <p className="welcome">
                    At Galaxbiotech, we integrate technology with clinical excellence to 
                    transform clinical research. Clinical research, which serves as the backbone
                    of modern medicine, demands innovation, precision, and efficiency.
                    Galaxbiotech is at the forefront of this transformation, Our next-generation
                    Clinical Trial Management Systems (CTMS) and complete solutions are
                    expertly created to fulfill the needs of the present-day, data-driven
                    healthcare environment. Our solutions are designed to be flexible and
                    adaptable, allowing them to fit seamlessly into your clinical research
                    workflow.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="abouts_container">
        <div className="about_page">
          <Container className="contains">
            <Row>
              <Col md={6} sm={12}>
                <div className="mt-5">
                  <p className="pionner" style={{ fontSize: 24, fontWeight: 500 }}>Our Legacy and Mission</p>
                  <p className="welcome" style={{ marginRight: 20 }}>
                    With our proficiency in biostatistics, clinical data management, and clinical
                    research, Galaxbiotech is transforming the conduct, management, and
                    monitoring of clinical trials. Our mission is focused and impactful: to equip
                    researchers, sponsors, and Contract Research Organizations (CROs) with
                    tools that boost efficiency, guarantee regulatory compliance, and accelerate
                    the journey from initial concept to life-saving treatments.
                  </p>
                </div>
              </Col>
              <Col md={6} sm={12} className="position-relative">
                <div className="dot_image">
                  <img src={dotImage} alt="" />
                </div>
                <div className="abouts_years p-5">
                  <img src={AboutImg3} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="aboutts_container">
        <div className="about_page">
          <Container className="contains">
            <Row>
              <Col md={5} sm={12} className="position-relative">
                <div className="circleDivs">
                  <div
                    style={{
                      width: '700px',
                      height: '70px',
                      borderRadius: "4pc",
                      backgroundColor: '#3d8e7a',
                      position: "absolute",
                      transform: "rotate(45deg)",
                      left: -103,
                      top: 200,
                      zIndex: 0,
                    }}>
                  </div>
                </div>
                <div className="about_rainvents py-4 mt-3">
                  <img src={ClinicT} alt="" style={{ position: "relative" }} />
                </div>
              </Col>
              <Col md={7} sm={12} className="aboutText" style={{ marginTop: -25 }}>
                <p className="pionner ms-4" style={{ fontSize: 24, fontWeight: 500 }}>What Sets Us Apart</p>
                <ul>
                  <li>
                    <p className="welcome"><span style={{ fontWeight: 500 }}>Innovative Technology:</span> Our CTMS solutions utilize advanced
                      technology to simplify processes and deliver actionable insights.</p>
                  </li>
                  <li>
                    <p className="welcome"><span style={{ fontWeight: 500 }}>Comprehensive Solutions:</span> From trial planning to data analysis, our
                      extensive toolset covers every facet of clinical trial management.</p>
                  </li>
                  <li>
                    <p className="welcome"><span style={{ fontWeight: 500 }}>Data-Informed Choices:</span> We use data to drive smart decision-making,
                      ensuring each step of the clinical trial process is optimized for
                      success.</p>
                  </li>
                  <li>
                    <p className="welcome"><span style={{ fontWeight: 500 }}>Dedication to Compliance:</span> Our solutions are built to meet stringent
                      regulatory standards, providing reassurance throughout the trial
                      lifecycle.</p>
                  </li>
                  <li>
                    <p className="welcome"><span style={{ fontWeight: 500 }}>Faster Results:</span> By improving efficiency and minimizing delays, we
                      speed up the path from initial concept to patient treatment, delivering
                      life-saving therapies more quickly.</p>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="about_containers1">
        <div className="about_page">
          <Container className="contains">
            <Row>
              <Col md={6} sm={12} style={{ paddingRight: 30 }}>
                <p className="pionner ms-4" style={{ fontSize: 24, fontWeight: 500 }}>Why Galaxbiotech?</p>
                <ul>
                  <li>
                    <p className="welcome"><span style={{ fontWeight: 500 }}>Expertise and Experience:</span> Backed by extensive experience in
                      clinical research and technology development, Galaxbiotech possesses
                      a profound understanding of industry challenges. Our team comprises
                      specialists in clinical trials, regulatory compliance, and technology
                      development, guaranteeing that our solutions adhere to the highest
                      standards.</p>
                  </li>
                  <li>
                    <p className="welcome"><span style={{ fontWeight: 500 }}>Innovative Technology:</span> We are committed to providing our clients
                      with the most advanced, innovative technology solutions available.</p>
                  </li>
                  <li>
                    <p className="welcome"><span style={{ fontWeight: 500 }}>Customization :</span> Every clinical trial is unique, which is why we offer
                      highly customizable solutions. Whether you need a tailor-made CTMS
                      or specific functionality to suit your study design, Galaxbiotech works
                      with you to ensure that our solutions meet your specific needs.</p>
                  </li>
                  <li>
                    <p className="welcome"><span style={{ fontWeight: 500 }}>Seamless Integration :</span> Our solutions are designed to seamlessly
                      integrate with your existing systems, ensuring a smooth transition and
                      minimal disruption to your workflow. Whether it’s integrating with
                      electronic health records (EHR), laboratory systems, or financial
                      management software, our team ensures that your systems work
                      together in harmony.</p>
                  </li>
                </ul>
              </Col>
              <Col md={6} sm={12} style={{ paddingLeft: 30 }}>
                <ul className="mt-5">
                  <li>
                    <p className="welcome"><span style={{ fontWeight: 500 }}>Commitment to Quality:</span> Quality is at the heart of everything we do.
                      We adhere to rigorous quality standards in every aspect of our work,
                      from system design and development to implementation and support.
                      Our goal is to deliver clinical research solutions that not only meet but
                      exceed industry expectations.</p>
                  </li>
                  <li>
                    <p className="welcome"><span style={{ fontWeight: 500 }}>Global Reach:</span> Clinical trials are increasingly global, and
                      Galaxbiotech has the tools and expertise to manage trials across
                      multiple geographies and regulatory environments. Our platform is
                      built to scale, enabling researchers to run large, multi-center trials
                      seamlessly.</p>
                  </li>
                  <li>
                    <p className="welcome"><span style={{ fontWeight: 500 }}>Dedicated Support:</span> Our customer support team is available around
                      the clock to assist with any technical or operational challenges.
                      Whether you need help with system setup, troubleshooting, or
                      training, Galaxbiotech’s dedicated team is here to ensure the success
                      of your clinical trial.</p>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="abouts_container">
        <div className="about_page">
          <Container className="contains">
            <Row>
              <Col md={6} sm={12}>
                <div className="aboutText" style={{ marginLeft: -20, marginTop: -50 }}>
                  <p className="pionner" style={{ fontSize: 24, fontWeight: 500 }}>Join Us in Revolutionizing Clinical Research</p>
                  <p className="welcome">
                    As a leader in clinical trial management technology, Galaxbiotech is here to
                    help you meet the demands of the modern healthcare environment. Let us
                    help you streamline your clinical trial process, improve patient outcomes,
                    and ultimately bring new treatments to the people who need them most.
                    Contact us today to learn more about how we can support your clinical
                    research initiatives and help you achieve your goals.
                  </p>
                </div>
              </Col>
              <Col md={6} sm={12} className="position-relative">
                <div className="abouts_yearss" style={{ marginLeft: 30 }}>
                <div className="circleDiv" style={{
                  width: '100px',
                  height: '100px',
                  backgroundColor: '#551962',
                  borderRadius: '50%',
                  position: "absolute",
                  left: -2,
                  top: -35,
                  zIndex: 0,
                }}>
                </div>
                <div
                  className="circleDiv"
                  style={{
                    width: '222px',
                    height: '85px',
                    borderRadius: "4pc",
                    backgroundColor: '#032e2d',
                    position: "absolute",
                    right: -66,
                    bottom: -11,
                    zIndex: 0,
                  }}>
                </div>
                  <img src={AiPower} alt="" style={{position: "relative"}}/>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default AboutPage;
