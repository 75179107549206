import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../CSS/producthome.css";
import { NavLink } from "react-router-dom";
import productImage from "../img/Product.png";

function Producthomepage() {
  return (
    <Container  fluid
    style={{
      padding: 0,
      margin: 0,
      width: "100vw",
      height: "100vh",
      overflow: "hidden",
    }}>
      <Row style={{ margin: 0, height: "100%", width: "100%" }}>
        <Col id="product" className="d-flex justify-content-center align-items-center"
          style={{ padding: 0, height: "100%" }}>
          <div className="product_homes" style={{ height: "50%", width: "75%" }}>
          <NavLink to={"/products"}>
            <img src={productImage}  alt="Products" style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}/>
            </NavLink>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Producthomepage;
