import React from "react";
import "../CSS/abouthomepage.css";
import { NavLink } from "react-router-dom";
import serviceImage from "../img/servicepage.png";
import { Col, Container, Row } from "react-bootstrap";

function Servicehomepage() {
  return (
    <Container fluid
      style={{
        padding: 0,
        margin: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
      }}>
      <Row style={{ margin: 0, height: "100%", width: "100%" }}>
        <Col id="service" className="d-flex justify-content-center align-items-center"
          style={{ padding: 0, height: "100%" }}>
          <div className="product_homes" style={{ height: "60%", width: "85%" }}>
            <NavLink to={"/service"}>
              <img src={serviceImage} alt="Services" style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }} />
            </NavLink>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Servicehomepage;
