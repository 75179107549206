import React from "react";
import "../CSS/producthome.css";
import { NavLink } from "react-router-dom";
import contactImage from "../img/Contact.png";
import { Col, Container, Row } from "react-bootstrap";

function Contacthomepage() {
  return (
    <Container
      fluid
      style={{
        padding: 0,
        margin: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Row style={{ margin: 0, height: "100%", width: "100%" }}>
        <Col
          id="content"
          className="d-flex justify-content-center align-items-center"
          style={{ padding: 0, height: "100%" }}
        >
          <div className="product_homes" style={{ height: "70%", width: "90%" }}>
            <NavLink to={"/contact-us"}>
              <img
                src={contactImage}
                alt="Contact Us"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </NavLink>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Contacthomepage;
